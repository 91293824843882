<template>
  <div>
    <div v-for="(qItem, qIndex) in questions" :key="qIndex" class="mb-3">
      <label>
        <div class="d-flex align-items-center">
          <strong>SORU - {{ qIndex + 1 }}</strong>
          <button
            type="button"
            class="btn btn-link"
            @click="deleteQuestion(qIndex)"
          >
            <i class="fas fa-trash"></i>
          </button>
        </div>
      </label>
      <textarea
        :name="`questions[${qIndex}][question]`"
        v-model="qItem.question"
        class="form-control question mb-2"
      ></textarea>
      <div class="my-2 font-weight-bold">
        <label>CEVAPLAR</label>
      </div>
      <div
        class="input-group my-2"
        v-for="(aItem, aIndex) in qItem.answers"
        :key="aIndex"
      >
        <div class="input-group-text">
          <input
            class="form-check-input mt-0"
            type="checkbox"
            v-model="aItem.correct"
            :name="`questions[${qIndex}][answers][${aIndex}][correct]`"
            :checked="aItem.correct"
          />
        </div>
        <input
          type="text"
          class="form-control"
          v-model="aItem.answer"
          :name="`questions[${qIndex}][answers][${aIndex}][answer]`"
        />
      </div>
    </div>
    <button type="button" class="btn btn-primary" @click="addQuestion">
      SORU EKLE
    </button>
  </div>
</template>

<script>
export default {
  props: ["items"],
  data() {
    return {
      questions: [],
    };
  },
  mounted() {
    this.questions = JSON.parse(this.items);
  },
  methods: {
    addQuestion() {
      this.questions.push({
        question: "",
        answers: [
          {
            answer: "",
          },
          {
            answer: "",
          },
          {
            answer: "",
          },
          {
            answer: "",
          },
        ],
      });
    },
    deleteQuestion(index) {
      this.questions.splice(index, 1);
    },
  },
  updated(){
    let editor = tinymce.init({
      selector: "textarea.question",
      menubar: false,
      toolbar_mode: "floating",
      language: "tr_TR",
      height: "200",
    });
  },
};
</script>
